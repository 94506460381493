.fallbackContainer {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.oops {
  font-size: 2em;
  font-weight: 500;
  color: rgb(55, 55, 55);
  margin: 2% 0 0 0;
}

.errorType {
  font-family: inherit;
  text-align: left;
}

.errorBtns {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  margin-top: 1%;
}

.tryAgainBtn {
  margin: 0 2% 0 0;
  padding: 2%;
  background-color: #294f7a;
  border-radius: 2px;
  color: white;
  border: transparent;
}
.goHomeBtn {
  padding: 2%;
  background-color: #294f7a;
  border-radius: 2px;
  color: white;
  border: transparent;
}

.tryAgainBtn:hover,
.goHomeBtn:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 500px) {
  .fallbackContainer {
    padding: 0 2%;
  }
  .oops {
    text-align: center;
    font-size: 1.5em;
  }
  .errorType {
    text-align: center;
    font-size: 0.9em;
    white-space: inherit;
  }
  .errorBtns {
    flex-direction: column;
  }
  .tryAgainBtn {
    padding: 5% 0;
    margin: 5% 0;
  }
  .goHomeBtn {
    padding: 5% 0;
  }
}
